import { useMemo } from "react"
import { FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa"
import { Box, Flex, IconButton, Link } from "@chakra-ui/react"
import AppConfig from "@common/constants/AppConfig"
import useIsMobile from "@common/hooks/useIsMobile"
import { SectionBg } from "@common/utils/theme"
import ImageElement from "./ImageElement"
import LandingSection from "./LandingSection"

const GlobalFooter = () => {
	const isMobile = useIsMobile()
	const currentYear = useMemo(() => new Date().getFullYear(), [])

	const footerIconButtonProps = {
		variant: "ghost",
		color: "#A0A7B3",
		fontSize: "1.5rem",
		_hover: {
			color: "section_color_dark",
		},
	}

	return (
		<LandingSection
			sectionBgColor={SectionBg.white}
			color="section_color_dark"
			customStyles={{ py: 12 }}
		>
			<Flex
				mt={2}
				direction={isMobile ? "column" : "row"}
				textAlign={isMobile ? "center" : "left"}
				justifyContent={"space-between"}
				width="100%"
			>
				<Flex
					textAlign={isMobile ? "center" : "left"}
					alignItems="center"
					direction="column"
				>
					{/* Logo */}
					<Box width="max-content">
						<Link href="https://cyberspatial.com" target="_blank">
							<ImageElement imageSrc="logos/logo_cyberspatial_icon.png" width={104} height={62} />
						</Link>
					</Box>

					{/* Social links */}
					<Box px={10} py={4}>
						<a
							href="https://www.youtube.com/c/cyberspatial"
							target="_blank"
							rel="noreferrer"
						>
							<IconButton
								aria-label="Youtube"
								icon={<FaYoutube />}
								{...footerIconButtonProps}
							/>
						</a>

						<a
							href="https://twitter.com/Cyberspatial_HQ"
							target="_blank"
							rel="noreferrer"
						>
							<IconButton
								aria-label="Twitter"
								icon={<FaTwitter />}
								{...footerIconButtonProps}
							/>
						</a>

						<a
							href="https://www.linkedin.com/company/cyberspatial"
							target="_blank"
							rel="noreferrer"
						>
							<IconButton
								aria-label="LinkedIn"
								icon={<FaLinkedin />}
								{...footerIconButtonProps}
							/>
						</a>
					</Box>
				</Flex>

				{/* Sitemap links */}
				<Flex
					flex={0.9}
					mt={isMobile ? 0 : 4}
					// ml={isMobile ? 0 : 12}
					direction={isMobile ? "column" : "row"}
					alignItems={isMobile ? "center" : "inherit"}
					gap={isMobile ? 4 : 4}
					flexBasis={0}
					justifyContent={"space-between"}
					textAlign={isMobile ? "center" : "right"}
					// sx={{ "_ > *": { flex: 1 } }}
					// mr={isMobile ? 0 : 24}
				>
					<Link href="/careers">Careers</Link>
					<Link href="/terms">Terms of Service</Link>
					<Link href="/privacy">Privacy Policy</Link>
					<Link href={"mailto:hello@cyberspatial.com"} target="_blank">
						Contact us
					</Link>
					<Link href="https://support.teleseer.com/docs" target="_blank">
						Docs
					</Link>
					{/* <Flex gap={16} mr={24}>
					<Box>
						<Text fontWeight={500} mb={2}>
							Product
						</Text>
						<Text>Why Teleseer?</Text>
						<Text>Use Cases</Text>
						<Text>Try Demo</Text>
						<Text>Integrations</Text>
						<Text>Pricing</Text>
					</Box>
					<Box>
						<Text fontWeight={500} mb={2}>
							Company
						</Text>
						<Text>About Cyberspatial</Text>
						<Text>Press and News</Text>
						<Text>Join the Team</Text>
						<Text>Contact Us</Text>
					</Box>
					<Box>
						<Text fontWeight={500} mb={2}>
							Docs
						</Text>
						<Text>Blog</Text>
						<Text>Release Notes</Text>
						<Text>Help Center</Text>
						<Text>FAQ</Text>
					</Box>
				</Flex> */}
				</Flex>
			</Flex>
			<Box
				fontSize="xs"
				textAlign={isMobile ? "center" : "right"}
				mt={isMobile ? 8 : -12}
				width="100%"
			>
				Copyright &copy; {currentYear}{" "}
				<Link href="https://cyberspatial.com" target="_blank">
					Cyberspatial Inc.
				</Link>{" "}
				All rights reserved.
			</Box>
		</LandingSection>
	)
}

export default GlobalFooter
