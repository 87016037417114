import Image from "next/legacy/image"
import { Box, Flex, FlexProps, SystemStyleObject } from "@chakra-ui/react"
import { MAX_LANDING_WIDTH_PX } from "@common/constants/ui"
import { SectionBg } from "@common/utils/theme"

interface LandingSection extends FlexProps {
	title?: string
	subtitle?: string
	customStyles?: SystemStyleObject
	sectionBgStyles?: {}
	sectionBgPlaceholder?: "blur" | "empty"
	sectionBgImage?: string
	sectionBgAlt?: string
	fullWidth?: boolean
	sectionBgAlign?: string
	sectionBgColor?: SectionBg
	ctaCaption?: string
	children?: React.ReactNode
	paddingY?: string
}

const LandingSection = ({
	title,
	subtitle,
	customStyles,
	sectionBgStyles,
	sectionBgPlaceholder,
	sectionBgImage,
	sectionBgAlt,
	sectionBgColor,
	sectionBgAlign,
	fullWidth,
	ctaCaption,
	height,
	children,
	borderRadius = "none",
	paddingY,
	...rest
}: LandingSection) => {
	let childContents = children

	if (sectionBgImage) {
		childContents = (
			<>
				<Image
					src={sectionBgImage}
					alt={sectionBgAlt || ctaCaption}
					layout="fill"
					objectFit="cover"
					objectPosition={sectionBgAlign || "center"}
					quality={80}
					placeholder={sectionBgPlaceholder || "blur"}
					// priority
					{...sectionBgStyles}
				/>
				<Box position="relative">{children}</Box>
			</>
		)
	} else {
		childContents = children
	}

	let boxStyles: SystemStyleObject = {
		px: { base: 6, md: "7%" },
		py: paddingY ?? "8vh",
		borderRadius,
		...customStyles,
	}

	const sectionColor = {
		[SectionBg.blue]: "bg_blue",
		[SectionBg.dark]: "bg_dark",
		[SectionBg.light_blue]: "bg_light_blue",
		[SectionBg.light]: "bg_section_hilight",
		[SectionBg.lighter]: "bg_lighter",
		[SectionBg.white]: "bg_white",
	}

	boxStyles.bg = sectionColor[sectionBgColor]

	const effectiveMaxWidth = fullWidth ? "100%" : `${MAX_LANDING_WIDTH_PX}px`

	return (
		<Box position="relative" sx={boxStyles}>
			<Flex
				direction="column"
				align="center"
				height={height}
				width={effectiveMaxWidth}
				maxWidth={"100%"}
				margin="auto"
				justifyContent="center"
				alignItems="center"
				{...rest}
			>
				{childContents}
			</Flex>
		</Box>
	)
}

export default LandingSection
