import NextLink from "next/link"
import { useRouter } from "next/router"
import { IoIosMenu } from "react-icons/io"
import { PiCaretDoubleRightBold } from "react-icons/pi"
import {
	Box,
	Button,
	Flex,
	FlexProps,
	HStack,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
} from "@chakra-ui/react"
import useIsMobile from "@common/hooks/useIsMobile"
import useViewport from "@common/hooks/useViewport"
import { CyberspatialLogo } from "@cyberspatial/components/CyberspatialLogo"
import { useState } from "react"

const NAV_MENU_BURGER_BREAKPOINT = 1000

const linkUnderlineBeforeStyle = {
	content: '""',
	position: "absolute",
	display: "block",
	width: "100%",
	height: "1.5px",
	bottom: "-3px",
	left: 0,
	backgroundColor: "white",
	transform: "scaleX(0)",
	transition: "opacity 0.3s ease, transform 0.3s ease",
}

export const linkStyle = {
	fontWeight: "400",
	textAlign: "center",
	position: "relative",
	textDecoration: "none",
	// opacity: ".75", // Eliminated navbar link text fade when not active or hovered, as per feedback from Ricky. CU-86a12qfkj

	_before: {
		...linkUnderlineBeforeStyle,
	},

	_hover: {
		// opacity: "1",
		_before: {
			transform: "scaleX(1)",
		},
	},
}

export const activeLinkStyle = {
	//opacity: "1",

	_before: {
		...linkUnderlineBeforeStyle,
		transform: "scaleX(1)",
	},
}

interface navLink {
	name: string
	path: string
}

const GlobalHeader = (props: FlexProps) => {
	const router = useRouter()
	const { visualWidth } = useViewport()
	const isMobile = useIsMobile()
	const isMobileNav = visualWidth < NAV_MENU_BURGER_BREAKPOINT

	const navLinks: navLink[] = [
		{ name: "Cyberspatial", path: "/company" },
		{ name: "Teleseer", path: "/teleseer/" },
		{ name: "Government", path: "/teleseer/government"},
		{ name: "How it Works", path: "/teleseer/how_it_works" },
		{ name: "Pricing", path: "/teleseer/pricing" },
		{ name: "News", path: "/news" },
		{ name: "Docs", path: "/docs" },
	]

	const isNavLinkActive = (link: navLink) => {
		if (link.path.endsWith("/"))
			return router.asPath === link.path || `${router.asPath}/` === link.path
		return router.asPath.startsWith(link.path)
	}

	const navButtonProps = {
		size: "xs",
		borderRadius: 16,
		height: 8,
		px: 6,
		sx: {
			transition: "opacity 0.3s ease",
		},
	}
	if (isMobile) {
		navButtonProps.px = 2
		navButtonProps.borderRadius = 8
	}

	const renderNavLink = (link: navLink, i, e) => {
		const isLogoLink = link.path.startsWith("/company")

		return (
			<NextLink
				key={link.path}
				href={link.path.replace(/\/$/, "")}
				legacyBehavior
			>
				<Link
					sx={{
						...linkStyle,
						...(isNavLinkActive(link) && activeLinkStyle),
						...(isLogoLink && { marginLeft: "55px", marginBottom: "4px" }),
					}}
				>
					{isLogoLink ? (
						<CyberspatialLogo
							logoStyle={"header"}
							marginLeft="-55px"
							marginBottom="-4px"
							marginRight="-4px"
						/>
					) : (
						link.name
					)}
				</Link>
			</NextLink>
		)
	}

	return (
		<>
			<Flex
				sx={{
					height: 16,
					alignItems: "center",
					justifyContent: "center",
					position: isMobile ? "relative" : "fixed",
					top: 0,
					left: 0,
					right: 0,
					zIndex: 100,
					backgroundColor: "bg_dark_transparent",
					backdropFilter: "blur(4px)",
					borderBottom: "none",
					borderColor: "divider",
					"a:hover": {
						color: "heading_text",
						textDecoration: "none",
					},
				}}
			>
				<Flex
					sx={{
						w: "100%",
						maxWidth: "1100px",
						mx: isMobile ? 4 : 8,
					}}
				>
					<Flex
						sx={{
							justifyContent: "space-between",
							color: "heading_text",
							margin: "auto",
						}}
						flex={1}
					>
						{/* Horizontal buttons */}
						{!isMobileNav && (
							<HStack align={"center"} spacing={"8"}>
								{navLinks.map(renderNavLink)}
							</HStack>
						)}
					</Flex>

					{/* Right Buttons */}
					<Flex gap="4" alignItems="center">
						<a
							href={"https://go.teleseer.com"}
							target="_blank"
							style={{
								textDecoration: "none",
							}}
						>
							<Button
								{...navButtonProps}
								variant="outline"
								borderColor="heading_text"
							>
								{isMobile ? "Login" : "Sign In"}
							</Button>
						</a>
						<a
							href={"https://demo.teleseer.com/"}
							target="_blank"
							style={{
								textDecoration: "none",
							}}
						>
							<Button
								{...navButtonProps}
								sx={{
									color: "heading_text",
									backgroundColor: "action_blue",
									_hover: {
										backgroundColor: "blue.400",
									},
								}}
								marginRight={isMobileNav && !isMobile ? 4 : 0}
							>
								{!isMobile && "Try "} Demo
							</Button>
						</a>

						{isMobileNav && (
							<Menu>
								<MenuButton as={Box}>
									<CyberspatialLogo
										logoStyle={"header"}
										position="absolute"
										left="18px"
										marginTop="2px"
									/>
									<IoIosMenu size="32px" />
								</MenuButton>
								<MenuList sx={{ background: "black" }}>
									{navLinks.map((link) => {
										const isActive = isNavLinkActive(link)
										return (
											<MenuItem
												key={link.path}
												as="a"
												href={link.path}
												sx={{
													background: "none",
													fontSize: "md",
												}}
												icon={
													<Box width="10px">
														{isActive && <PiCaretDoubleRightBold />}
													</Box>
												}
											>
												{link.name}
											</MenuItem>
										)
									})}
								</MenuList>
							</Menu>
						)}
					</Flex>
				</Flex>
			</Flex>
			{!isMobile && <Box mb="64px"></Box>}
		</>
	)
}

export default GlobalHeader
