import { useEffect, useState } from "react"

const useIsMobile = (): boolean => {
	const [isMobile, setisMobile] = useState<boolean>(false)

	useEffect(() => {
		function handleResize() {
			setisMobile(document.documentElement.clientWidth < 768)
		}

		// Add event listener
		window.addEventListener("resize", handleResize)

		// Call handler right away so state gets updated with initial window size
		handleResize()

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize)
	}, []) // Empty array ensures that effect is only run on mount

	return isMobile
}

export default useIsMobile
