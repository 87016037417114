import Image from "next/legacy/image"
import { Box } from "@chakra-ui/react"

const images = require.context("/public/img", true)

interface ImageElementProps {
	imageSrc: string
	width?: number
	height?: number
}

const ImageElement = ({
	imageSrc,
	width = 80,
	height = width ?? 80,
}: ImageElementProps): JSX.Element => {
	return (
		<Box minWidth={`${width}px`}>
			<Image
				// placeholder={"blur"}
				src={images("./" + imageSrc)}
				alt={""}
				width={width}
				height={height}
			/>
		</Box>
	)
}

export default ImageElement
