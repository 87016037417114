import { useCallback, useEffect, useState } from "react"

function throttle(callback: Function, delay: number) {
	var waiting = false // Initially, we're not waiting
	return function () {
		// We return a throttled function
		if (!waiting) {
			// If we're not waiting
			callback.apply(this, arguments) // Execute users function
			waiting = true // Prevent future invocations
			setTimeout(function () {
				// After a period of time
				waiting = false // And allow future invocations
			}, delay)
		}
	}
}

export default function useThrottle(value, delay: number) {
	const [throttledValue, setThrottledValue] = useState(value)

	const throttled = useCallback(
		(newValue) => throttle((newValue) => setThrottledValue(newValue), delay),
		[delay]
	)
	useEffect(() => throttled(value), [throttled, value])

	return throttledValue
}
