export const LANDING_TEXT_PADDING_BOTTOM = [".2em", ".5rem"]
export const LANDING_TEXT_PADDING_GENEROUS = [".75rem", "1rem", "1.5rem"]
export const MAX_LANDING_WIDTH_PX = 1100
export const MAGIC_BORDER_GRADIENT =
	"linear-gradient(160deg,#5ddcff,#3c67e3,#4e00c2)"
export const MAGIC_BORDER_GRADIENT_RED =
	"linear-gradient(160deg,#CA03B6,#FF4789)"
export const TEXT_BLUE = "#3096ed"
export const TEXT_RED = "#FF4789"
export const MAGIC_TEXT_GRADIENT = "linear(to-r, #3096ed, #e658a1)"
export const BLUE_GRADIENT = "linear(315deg, #3096ed, #3c67e3)"
export const BLUE_GRADIENT_HOVER = "linear(135deg, #3096ed, #3c67e3)"
export const RED_GRADIENT = "linear(315deg, #FF4789, #CA03B6)"
export const RED_GRADIENT_HOVER = "linear(135deg, #FF4789, #CA03B6)"
