import { useEffect, useState } from "react"
import useThrottle from "./useThrottle"

interface Size {
	width: number | undefined
	height: number | undefined
	visualWidth: number | undefined
	visualHeight: number | undefined
	clientWidth: number | undefined
	clientHeight: number | undefined
}

const useViewport = (): Size => {
	const [windowSize, setWindowSize] = useState<Size>({
		width: 0,
		height: 0,
		visualWidth: 0,
		visualHeight: 0,
		clientWidth: 0,
		clientHeight: 0,
	})

	useEffect(() => {
		function handleResize() {
			const newSize = {
				width: window.innerWidth,
				height: window.innerHeight,
				visualWidth: Math.min(window.visualViewport.width, screen.width),
				visualHeight: Math.min(window.visualViewport.height, screen.height),
				clientWidth: document.documentElement.clientWidth,
				clientHeight: document.documentElement.clientHeight,
			}
			setWindowSize(newSize)
		}

		// Add event listener
		window.addEventListener("resize", handleResize)

		// Call handler right away so state gets updated with initial window size
		handleResize()

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize)
	}, []) // Empty array ensures that effect is only run on mount

	const debouncedWindowSize = useThrottle(windowSize, 250)
	return debouncedWindowSize || windowSize
}

export default useViewport
